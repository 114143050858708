import React,{} from 'react';
import DownloadSummaryTemp from '../downloadSummaryTemp';
import * as constants from '../../../../../constants';
import Viewer2DDownLoad from './viewer2DDownLoad';

const Show2DView = ({props}) => {

  return (
    <DownloadSummaryTemp>
      <div style={{marginBottom:'10px'}}>
        <h2 style={{marginBottom:'auto'}}>{constants.TWO_D_FLOOR_PLAN}</h2>
        <h5 style={{marginTop:7, color:'rgba(120,120,120,0.8)'}}>{constants.TECHNICAL_VIEW}</h5>
      </div>
      <div style={{height:412}}>
        <div style={{transform: `scale(0.5, 0.5)`, width:6, height:2}}>
          <Viewer2DDownLoad state={props.state} catalog={props.catalog} width={786} height={700} />
        </div>
      </div>
    </DownloadSummaryTemp>
  );
};

export default Show2DView;
