import React from 'react';
import DownloadSummaryTemp from './downloadSummaryTemp';
import * as constants from '../../../../constants';

const ShowCabinetInfo = ({ productDATA, totalPrice }) => {

  return (
    <DownloadSummaryTemp>
      <div style={{}}>
        <h2 style={{marginBottom:'auto'}}>{constants.LIST_PARTS}</h2>
        <h5 style={{marginTop:7, color:'rgba(120,120,120,0.8)'}}>{constants.LIST_QUANTITIES}</h5>
      </div>
      <div style={{ marginTop:'30px'}}>
        <div style={styles.tHeader}>
          <h6>{constants.PRODUCT}</h6>
          <h6>{constants.SUBTOTAL}</h6>
        </div>
        <div>
          <div style={{}}>
            {productDATA}
          </div>
          <div style={styles.totalPrice}>
            <h6>Total</h6>
            <h6>${totalPrice}</h6>
          </div>
        </div>
      </div>
    </DownloadSummaryTemp>
  );
}

const styles =({
  tHeader:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    height:'25px',
    backgroundColor: "#FAFAFF",
    padding:'0 4px 0 20px',
    color:'rgba(120,120,120,0.8)'
  },
  totalPrice:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    height:'25px',
    backgroundColor: "#FAFAFF",
    padding:'0 0 0 20px',
    color: 'rgba(120,120,120,1)',
    marginTop: 12
  },
});

export default ShowCabinetInfo;
