import React from 'react';
import moment from 'moment';

const DownloadSummaryTemp = ({ children }) => {
  return (
    <div style={ styles.container }>
      <div style={ styles.header }>
        <div style={ styles.header_log }>
          <div style={{ height: '40px', width: '40px', backgroundColor: 'blueviolet' }}>
            <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
          </div>
          <span style={{ padding: '0 10px' }}>{sessionStorage.getItem('projectTitle')}</span>
        </div>
        <span>Created for: {sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</span>
        <span>Date: {moment(new Date()).format('DD-MM-YYYY')}</span>
        <span>ID: #{sessionStorage.getItem('projectId')}</span>
      </div>
      {children}
      <div style={{ textAlign: 'justify', margin: '30px 0 40px 0', fontSize:'10px', color:'rgba(120,120,120,0.8)' }}>
        <b>Disclaimer:</b> DIY Design Space not take responsibility for the
          accuracy of measurements or furniture arrangements. The prices
          displayed here apply to items in stock at the time of the offer's
          issuance. All delivery, assembly, and installation services required
          are billed are billed separately and are not included in the price.
          While we strive to ensure the accuracy of the information in this
          program, we apologize for any errors that may occur.
      </div>
      <div style={ styles.footer }>
        <span>© DIY.DS v1.0/2024</span>
        <span>DIY Design Space® 2024 All rights reserved</span>
      </div>
    </div>
  );
}

const styles = ({
  container:{
    width: '454px',
    // height: '784px',
    // minHeight:'100%',
    backgroundColor: '#fff',
    padding: '30px 30px',
    marginBottom: '10px',
    borderRadius: '5px',
    fontFamily: 'sans-serif',
  },
  header:{
    width:'100%',
    display: 'flex',
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 0',
    borderBottom: '1px solid rgba(119,119,119,0.47)',
    color: 'rgba(119,119,119,0.9)',
    fontSize: '10px',
  },
  header_log: {
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer:{
    padding: '15px 0',
    borderTop: '1px solid rgba(119,119,119,0.47)',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'sans-serif',
    color: 'rgba(119,119,119,0.9)',
    fontSize: '10px',
  }
});

export default DownloadSummaryTemp;
