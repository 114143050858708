import React, {useEffect, useState} from 'react';
import DownloadSummaryTemp from "../downloadSummaryTemp";
import * as constants from "../../../../../constants";
import {
  MODE_BACK_ELEVATION_VIEW,
  MODE_FRONT_ELEVATION_VIEW,
  MODE_LEFT_ELEVATION_VIEW,
  MODE_RIGHT_ELEVATION_VIEW
} from "../../../../../constants";
import ViewerRightElevationDownLoad from "./viewerRightElevationDownload";

const ShowRightElevationView = ({props}) => {
  let newState = props.state.set('mode',MODE_RIGHT_ELEVATION_VIEW);

  const [hasRightWall, setHasRightWall] = useState(true);

  useEffect(() => {
    let layer = props.state.scene.getIn(["layers", props.state.scene.selectedLayer]);
    if(!layer.lines.size){
      // alert("You must add a least one wall");
      // return;
      setHasRightWall(false);
    } else {
      let directLines = [];
      let minX, maxX, minY, maxY, flag = true;
      layer.lines.toArray().forEach(line => {
        let vertex0 = layer.vertices.get(line.vertices.get(0));
        let vertex1 = layer.vertices.get(line.vertices.get(1));
        let { x: x1, y: y1 } = vertex0;
        let { x: x2, y: y2 } = vertex1;
        if(flag) {
          minX = x1;
          maxX = x2;
          minY = y1;
          maxY = y2;
          flag = false;
        }
        if (x1 > x2) {
          if(x1 > maxX) maxX = x1;
          if(x2 < minX) minX = x2;
        } else {
          if (x1 == x2) {
            if (x1 > maxX) maxX = x1;
            if (x1 < minX) minX = x1;
          } else {
            if(x2 > maxX) maxX = x2;
            if(x1 < minX) minX = x1;
          }
        }

        if (y1 > y2) {
          if(y1 > maxY) maxY = y1;
          if(y2 < minY) minY = y2;
        } else {
          if (y1 == y2) {
            if (y1 > maxY) maxY = y1;
            if (y1 < minY) minY = y1;
          } else {
            if(y2 > maxY) maxY = y2;
            if(y1 < minY) minY = y1;
          }
        }
      });
      layer.lines.toArray().forEach(line => {
        let vertex0 = layer.vertices.get(line.vertices.get(0));
        let vertex1 = layer.vertices.get(line.vertices.get(1));
        let { x: x1, y: y1 } = vertex0;
        let { x: x2, y: y2 } = vertex1

        switch(newState.mode){
          case MODE_FRONT_ELEVATION_VIEW:
            if(minY == maxY) {
              if(y1 === y2 && x1 < x2){
                directLines.push(line);
              }
            } else {
              if(y1 === y2 && y1 == maxY){
                directLines.push(line);
              }
            }
            break;
          case MODE_BACK_ELEVATION_VIEW:
            if (minY == maxY) {
              if (y1 === y2 && x1 > x2) {
                directLines.push(line);
              }
            } else {
              if(y1 === y2 && y1 == minY){
                directLines.push(line);
              }
            }
            break;
          case MODE_LEFT_ELEVATION_VIEW:
            if(minX == maxX) {
              if(x1 === x2 && y1 < y2) {
                directLines.push(line);
              }
            } else {
              if(x1 === x2 && x1 == minX){
                directLines.push(line);
              }
            }
            break;
          case MODE_RIGHT_ELEVATION_VIEW:
            if(minX == maxX) {
              if(x1 === x2 && y1 > y2) {
                directLines.push(line);
              }
            } else {
              if(x1 === x2 && x2 == maxX){
                directLines.push(line);
              }
            }
            break;
          default:
            break;
        }
      });
      if(!directLines.length) {
        setHasRightWall(false);
      }
    }
  },[]);

  return (
    <DownloadSummaryTemp>
      <div style={{marginBottom:'10px'}}>
        <h2 style={{marginBottom:'auto'}}>{constants.ELEVATION_VIEW_RIGHT}</h2>
        <h5 style={{marginTop:7, color:'rgba(120,120,120,0.8)'}}>{constants.TECHNICAL_VIEW}</h5>
      </div>
      { hasRightWall && <div style={{height:412}}> <ViewerRightElevationDownLoad state={newState} catalog={props.catalog}/> </div>}
      { !hasRightWall && <div style={Style.noWall}>
        <h1>No Right Wall</h1>
      </div>
      }
    </DownloadSummaryTemp>
  );
};

const Style = ({
  noWall:{
    width: '393px',
    height : '350px',
    backgroundColor:'lightgrey',
    textAlign:'center',
    padding:'150px 0'
  }
});

export default ShowRightElevationView;
