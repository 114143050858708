import React, {useEffect, useState} from 'react';
import State3DDownload from "./ruler-utils-download/state3DDownload";
import Viewer3DRightElevationDownload from "./viewer3DRightElevationDownload";
import {getSignedUrl} from "../../../../../utils/helper";

let k = 0;
const ViewerRightElevationDownLoad = ({state,catalog}) => {
  const [mode, setMode] = useState('');
  if(mode !== state.get('mode')){
    k++;
    setMode(state.get('mode'));
  }
  const width = 786, height = 500;

  useEffect(()=>{
    setTimeout(()=>saveSVGScreenshotToFile(),5000);
  });

  const saveSVGScreenshotToFile = async () => {
    let maxWidthSVGElement = document.getElementById("rightElevation");
    if(maxWidthSVGElement !== null) {
      let images = maxWidthSVGElement.getElementsByTagName('image');

      let readBlob = b => {
        return new Promise(res => {
          const reader = new FileReader();
          reader.onloadend = () => {
            res(reader.result);
          };
          reader.onerror = () => {
            res(b);
          };
          reader.readAsDataURL(b);
        });
      };

      try {
        for (let image of images) {
          const response = await fetch(
            getSignedUrl(image.getAttribute('href'))
          );
          const blob = await response.blob();
          const content = await readBlob(blob);
          image.setAttribute('href', content);
        }
      } catch (e) {}
      // End: Change Image tags' href to local data src
    }
    let serializer = new XMLSerializer();

    let img = new Image();
    let img2 = new Image();
    // I create the new canvas to draw
    let canvas2D3DPlain1 = document.getElementById("pdfRightEV");

    if (canvas2D3DPlain1 !== null) {

      let canvas2D3DPlain = canvas2D3DPlain1.getElementsByTagName('canvas')[0];

      let canvas42DPlain = document.createElement('canvas');
      let ctx = canvas42DPlain.getContext('2d');

      // Set width and height for the new canvas
      let heightAtt = document.createAttribute('height');
      heightAtt.value = maxWidthSVGElement.height.baseVal.value;
      canvas42DPlain.setAttributeNode(heightAtt);

      let widthAtt = document.createAttribute('width');
      widthAtt.value = maxWidthSVGElement.width.baseVal.value;
      canvas42DPlain.setAttributeNode(widthAtt);

      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas42DPlain.width, canvas42DPlain.height);

      img.crossOrigin = 'anonymous';
      img.src = `data:image/svg+xml;base64,${window.btoa(
        serializer.serializeToString(maxWidthSVGElement)
      )}`;
      img2.crossOrigin='anonymous';
      img2.src = canvas2D3DPlain.toDataURL();

      img2.onload = () => {
        ctx.globalAlpha = 1;
        ctx.drawImage(
          img2,
          0,
          0,
          maxWidthSVGElement.width.baseVal.value,
          maxWidthSVGElement.height.baseVal.value
        );
        ctx.drawImage(
          img,
          0,
          0,
          maxWidthSVGElement.width.baseVal.value,
          maxWidthSVGElement.height.baseVal.value
        );

        window.rightElevationImg = canvas42DPlain.toDataURL();
      };
    } else {
      window.rightElevationImg = '';
    }
  };

  return (
    <div id="pdfRightEV" style={{position:'relative',  transform:`scale(0.5,.5)`, width:6, height:0}}>
      <svg id="rightElevation" width={width} height={height} style={{position:"absolute" }}>
        <g transform={`translate(${width/2}, ${height/2})`}>
          <State3DDownload state={state} catalog={catalog} width={width} height={height}/>
        </g>
      </svg>
      <Viewer3DRightElevationDownload key={k} state={state} width={width} height={height}/>
    </div>
  );
};

export default ViewerRightElevationDownLoad;
