import React from 'react';
import convert from 'convert-units';
import Layer3DDownload from './layer3DDownload';
import { MODE_FRONT_ELEVATION_VIEW, MODE_BACK_ELEVATION_VIEW, MODE_LEFT_ELEVATION_VIEW, MODE_RIGHT_ELEVATION_VIEW } from '../../../../../../constants';

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export default function Scene3DDownload({scene, catalog, width, height, mode}) {

  let { layers } = scene;
  let selectedLayer = layers.get(scene.selectedLayer);
  let ceilHeight = selectedLayer.ceilHeight;
  let vertices = selectedLayer.vertices;
  let maxX = 0, maxY = 0, minX = 0, minY = 0;

  let lines = [];
  selectedLayer.lines.toArray().forEach(line => {
    let vertex0 = selectedLayer.vertices.get(line.vertices.get(0));
    let vertex1 = selectedLayer.vertices.get(line.vertices.get(1));
    let { x: x1, y: y1 } = vertex0;
    let { x: x2, y: y2 } = vertex1;
    let data = line.toJS();
    data = {...data, l0: { x: x1, y: y1 }, l1: { x: x2, y: y2 }}
    switch(mode){
      case MODE_FRONT_ELEVATION_VIEW:
        if(y1 === y2 && x1 < x2){
          lines.push(data);
        }
        break;
      case MODE_BACK_ELEVATION_VIEW:
        if(y1 === y2 && x1 > x2){
          lines.push(data);
        }
        break;
      case MODE_LEFT_ELEVATION_VIEW:
        if(x1 === x2 && y1 < y2){
          lines.push(data);
        }
        break;
      case MODE_RIGHT_ELEVATION_VIEW:
        if(x1 === x2 && y1 > y2){
          lines.push(data);
        }
        break;
      default:
        break;
    }
  });
  vertices.toArray().forEach(vertex => {
    if (lines.filter(line => line.vertices.includes(vertex.id)).length){
      maxX = Math.max(vertex.x, maxX);
      maxY = Math.max(vertex.y, maxY);
      if(minX === 0) minX = vertex.x;
      if(minY === 0) minY = vertex.y;
      minX = Math.min(vertex.x, minX);
      minY = Math.min(vertex.y, minY);
    }
  })


  let line_length = 0;
  switch(mode){
    case MODE_FRONT_ELEVATION_VIEW:
    case MODE_BACK_ELEVATION_VIEW:
      line_length = maxX - minX;
      break;
    case MODE_RIGHT_ELEVATION_VIEW:
    case MODE_LEFT_ELEVATION_VIEW:
      line_length = maxY - minY;
      break;
  }
  line_length = convert(line_length).from(scene.unit).to(scene.rulerUnit);
  let scale = Math.min(width * 0.85 / line_length, height * 0.85 / ceilHeight);
  let frontRect = {width: line_length * scale, height: ceilHeight * scale};

  let i = 0, grids = [];
  let viewScale = Math.min(width / window.innerWidth, height / window.innerHeight);

  for(let x = -width / 2; x <= width / 2; x = x + 30 * viewScale){
    for(let y = -height / 2; y <= height / 2; y = y + 30 * viewScale){
      if(Math.abs(x) > frontRect.width / 2 || Math.abs(y) > frontRect.height / 2){
        grids.push(<circle key={`grid_${i}`} cx={x} cy={y} r={2 * viewScale} fill="#bbb"/>);
        i++;
      }
    }
  }
  return (
    <g>
      <polygon points={`-${width / 2},-${height / 2} ${width / 2},-${height / 2} ${width / 2},-${frontRect.height / 2} -${width / 2},-${frontRect.height / 2}`} fill="white" />
      <polygon points={`-${width / 2},${height / 2} ${width / 2},${height / 2} ${width / 2},${frontRect.height / 2} -${width / 2},${frontRect.height / 2}`} fill="white" />
      <polygon points={`-${width / 2},-${height / 2} -${frontRect.width / 2},-${height / 2} -${frontRect.width / 2},${height / 2} -${width / 2},${height / 2}`} fill="white" />
      <polygon points={`${width / 2},-${height / 2} ${frontRect.width / 2},-${height / 2} ${frontRect.width / 2},${height / 2} ${width / 2},${height / 2}`} fill="white" />
      {grids}
      <Layer3DDownload key={selectedLayer.id} layer={selectedLayer} frontRect={frontRect} lineLength={line_length}
               ceilHeight={ceilHeight.toFixed(0)} catalog={catalog} scene={scene} scale={scale}
               minX={minX} minY={minY  } maxX={maxX}  maxY={maxY} mode={mode} lines={lines} viewScale={viewScale}
      />
    </g>
  )
}
