import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import ReviewItem from '../product/reviewItem';
import {
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_5,
  BG_COLOR_1,
  API_SERVER_URL
} from '../../../../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '../../main/styles';
import moment from 'moment';
import ShowCabinetInfo from './showCabinetInfo';
import Show2DView from './show2D/show2DView';
import Show3DView from './show3D/show3DView';
import ShowFrontElevationView from "./showElevation/showFrontElevationView";
import ShowBackElevationView from "./showElevation/showBackElevationView";
import ShowLeftElevationView from "./showElevation/showLeftElevationView";
import ShowRightElevationView from "./showElevation/showRightElevationView";
import ShowWarranty from "./showWarranty";
import axios from 'axios';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 5px;
    max-width: unset;
    width: 47%;
    height : 75%;
    position: relative;
  }
  //::-webkit-scrollbar {
  //  width: 2px;
  //  border-radius: 2px;
  //  margin-left: 3px;
  //}
  //::-webkit-scrollbar-track-piece {
  //    background-color: #ff0000;
  //    border-radius: 1px;
  //}
`;

const DesignViewer = styled.div`
  width: 55%;
  border-style: solid;
  border-width: 10px 10px 10px 20px;
  border-color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
  padding-right: 10px;
  overflow:auto;
  ::-webkit-scrollbar {
    width: 2px;
    position : absolute;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: rgba(0, 0, 0,0.2);
    position: absolute;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgb(220, 220, 220);;
    border-radius: 5px;
    transition: all 0.3s;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: rgb(193, 202, 228);;
    border-radius: 5px;
    transition: all 0.3s;
  }
`;

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class DownloadSummaryPopup extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props)
    // let layer = props.layers.get(props.selectedLayer);
    // let elements = {
    //   lines: layer.lines,
    //   holes: layer.holes,
    //   items: layer.items,
    //   molding: layer.molding
    // };
    // let ceilingHeight = props.state.scene.layers.get(props.selectedLayer).ceilHeight;
    // // let measurementUnit = unitData.filter(unit => unit._unit === props.state.scene.layers.get(props.selectedLayer).unit)[0];

    this.state = {
      // elements : elements,
      // measurementUnit : measurementUnit,
      email: sessionStorage.getItem('email') || '',
      emailfocusOn: false,
      phone: sessionStorage.getItem('phone') || '',
      phonefocusOn: false,
      firstName: sessionStorage.getItem('firstName') || '',
      firstNamefocusOn: false,
      lastName: sessionStorage.getItem('lastName') || '',
      lastNamefocusOn: false,
      projectTitlefocusOn: false,
      projectTitle: sessionStorage.getItem('projectTitle'),
      question1: false,
      question2: false,
      question3: false,
      image : '',
      isLoading: false
    };
  }

  _closePanel() {
    this.props.setDownloadPopupVisible(false);
  }

  render() {
    const { visible } = this.props;
    const {
      firstName,
      firstNameFocusOn,
      lastName,
      lastNameFocusOn,
      email,
      emailFocusOn,
      phone,
      phoneFocusOn,
      projectTitle,
      projectTitleFocusOn,
      question1,
      question2,
      question3,
      image,
      isLoading
    } = this.state;

  // let canvas = document.getElementById('viewer3D');

  // let heightAtt = canvas.height;

  // let widthAtt = canvas.width;

  // let img = new Image ();
  // img.crossOrigin = 'anonymous';
  // img.src = canvas.toDataURL();

  // img.onload = () => {
  //   ctx.drawImage(img, 0, 0, widthAtt, heightAtt);
  //   this.setState({
  //     image: img
  //   });
  // };

    const pdfBrowserDownload = (imageUri) => {
      let fileOutputLink = document.createElement('a');
      let filename = imageUri.slice(imageUri.search('summary'), imageUri.length);

      fileOutputLink.setAttribute('download', filename);
      fileOutputLink.setAttribute('target', '_blank');
      fileOutputLink.href = process.env.API_URL + '/uploads/doc/' + filename;
      fileOutputLink.style.display = 'none';
      document.body.appendChild(fileOutputLink);
      setTimeout(() => {
        fileOutputLink.click();
      }, 100);
      document.body.removeChild(fileOutputLink);
      this.setState({
        isModalVisible: false,
        imageUri: '',
        image: ''
      });

      document.removeEventListener('keydown', this.onKeyDown, false);
      document.removeEventListener(
        'mouseup',
        this.onMouseUp_On_New_Save_SQ_DA,
        false
      );

    }

    const downloadSummary = async () => {

      // for imgCapture
      let img3D = 'false';
      let img2DPlain = 'false', imgFront = 'false', imgBack = 'false', imgLeft = 'false', imgRight = 'false';

      // for header
      const projectTitle = projectTitle;
      const creator = lastName + firstName;
      const createDate = moment(new Date()).format('DD-MM-YYYY');
      const projectID = sessionStorage.getItem('projectId');
      // for project save
      const projectData = JSON.stringify(this.props.state.get('scene').toJS());
      // for cabinet list
      const cabinetList = window.uniqueData;
      // for questions
      const questions = [question1,question2,question3];
      this.setState({isLoading: true});

      if (question1) {
        img3D =  window.pdf3DPlain;
      }
      if (question2) {
        img2DPlain = window.pdf2DPlain;
        imgFront = window.frontElevationImg;
        imgBack = window.backElevationImg;
        imgLeft = window.leftElevationImg;
        imgRight = window.rightElevationImg;
      }

      axios
        .post(`${API_SERVER_URL}/api/project/downloadPDF`, {
          img1: img3D,
          img2: [img2DPlain, imgFront, imgBack,imgLeft, imgRight],
          projectTitle: projectTitle,
          creator:creator,
          createDate:createDate,
          projectID:projectID,
          projectData: projectData,
          cabinetList: cabinetList,
          question: questions
        })
        .then(async res => {
          this.setState({isLoading: false});
          pdfBrowserDownload(res.data.fileName);
        });

    }

    return (
      <div>
        <StyledDialog id="showInfo" open={visible} onClose={() => this._closePanel()}>
          {isLoading && <div style = {{ position: 'absolute', alignItems: "center", width: "100%", height: "100%", opacity: '0.8', background: 'gray', zIndex: '10', justifyContent: "center", display: "flex"}}>
            <img style = {{ animation: "spin 2s linear infinite"}} src={"/assets/img/loading_large.gif"} alt="img" />
          </div>}
          <div style={{ display: 'flex', width: '100%', userSelect: 'none ', height : "100%" }}>
            <div
              style={{
                width: '45%',
                display: 'flex',
                padding: '40px 40px 40px 40px',
                flexDirection: 'column',
                overflow:'auto'
              }}
            >
              <S.DialogTitle>Download Summary</S.DialogTitle>
              <S.DialogContent style={{ margin: '20px 0' }}>
                Your are almost there, customize your summary based on your
                requirements.
              </S.DialogContent>
              <div style={{height:'80%'}}>
                {
                  (sessionStorage.getItem('email') === null) &&
                  <div style={{}}>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={firstName}
                        onChange={e => this.setState({ firstName: e.target.value })}
                        onFocus={e => this.setState({ firstNameFocusOn: true })}
                        onBlur={e => this.setState({ firstNameFocusOn: false })}
                        placeholder={
                          firstNameFocusOn ? 'Enter First Name' : 'First Name'
                        }
                        required
                      />
                      {(firstNameFocusOn || !!firstName.length) && (
                        <S.StyledInputHeadLabel
                          style={{ color: !firstNameFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                        >
                          First Name
                        </S.StyledInputHeadLabel>
                      )}
                      {!!firstName.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ firstName: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={lastName}
                        onChange={e => this.setState({ lastName: e.target.value })}
                        onFocus={e => this.setState({ lastNameFocusOn: true })}
                        onBlur={e => this.setState({ lastNameFocusOn: false })}
                        placeholder={lastNameFocusOn ? 'Enter Last Name' : 'Last Name'}
                        required
                      />
                      {(lastNameFocusOn || !!lastName.length) && (
                        <S.StyledInputHeadLabel
                          style={{ color: !lastNameFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                        >
                          Last Name
                        </S.StyledInputHeadLabel>
                      )}
                      {!!lastName.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ lastName: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={email}
                        onChange={e => this.setState({ email: e.target.value })}
                        onFocus={e => this.setState({ emailFocusOn: true })}
                        onBlur={e => this.setState({ emailFocusOn: false })}
                        placeholder={emailFocusOn ? 'Enter Email' : 'Email'}
                        required
                      />
                      {(emailFocusOn || !!email.length) && (
                        <S.StyledInputHeadLabel
                          style={{ color: !emailFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                        >
                          Email
                        </S.StyledInputHeadLabel>
                      )}
                      {!!email.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ email: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={phone}
                        onChange={e => this.setState({ phone: e.target.value })}
                        onFocus={e => this.setState({ phoneFocusOn: true })}
                        onBlur={e => this.setState({ phoneFocusOn: false })}
                        placeholder={
                          phoneFocusOn ? 'Enter Phone Number' : 'Phone Number'
                        }
                        required
                      />
                      {(phoneFocusOn || !!phone.length) && (
                        <S.StyledInputHeadLabel
                          style={{ color: !phoneFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                        >
                          Phone Number
                        </S.StyledInputHeadLabel>
                      )}
                      {!!phone.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ phone: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                    <S.StyledInputWrapper>
                      <S.StyledInput
                        value={projectTitle}
                        onChange={e => this.setState({ projectTitle: e.target.value })}
                        onFocus={e => this.setState({ projectTitleFocusOn: true })}
                        onBlur={e => this.setState({ projectTitleFocusOn: false })}
                        placeholder={
                          projectTitleFocusOn ? 'Enter Project Title' : 'Project Title'
                        }
                        required
                      />
                      {(projectTitleFocusOn || !!projectTitle.length) && (
                        <S.StyledInputHeadLabel
                          style={{
                            color: !projectTitleFocusOn && TEXT_COLOR_NEUTRAL_2
                          }}
                        >
                          Project Title
                        </S.StyledInputHeadLabel>
                      )}
                      {!!projectTitle.length && (
                        <S.PlusImage
                          maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                          onClick={() => this.setState({ projectTitle: '' })}
                        />
                      )}
                    </S.StyledInputWrapper>
                  </div>
                }
                <S.DialogContent style={{ margin: '10px 0px' }}>
                  What you want to print:
                </S.DialogContent>
                <FormControlLabel
                  style={{ margin: '10px 0px' }}
                  control={
                    <StyledMuiCheckbox
                      style={{ padding: 'unset' }}
                      checked={question1}
                      onChange={() => this.setState({ question1: !question1 })}
                      value={question1}
                    />
                  }
                  label={
                    <S.DialogContents>3D render of the kitchen</S.DialogContents>
                  }

                />
                <FormControlLabel
                  style={{ margin: '10px 0px' }}
                  control={
                    <StyledMuiCheckbox
                      style={{ padding: 'unset' }}
                      checked={question2}
                      onChange={() => this.setState({ question2: !question2 })}
                      value={question2}
                    />
                  }
                  label={
                    <S.DialogContents>
                      2D floor plan and elevations technical view
                    </S.DialogContents>
                  }
                />
                <FormControlLabel
                  style={{ margin: '10px 0px' }}
                  control={
                    <StyledMuiCheckbox
                      style={{ padding: 'unset' }}
                      checked={question3}
                      onChange={() => this.setState({ question3: !question3 })}
                      value={question3}
                    />
                  }
                  label={
                    <S.DialogContents>
                      List of parts with quantities and pricing
                    </S.DialogContents>
                  }
                />
              </div>
              <S.SaveBox onClick={downloadSummary}>
                <S.SelectTitle style={{color: BG_COLOR_1}}>Save & Download Summary</S.SelectTitle>
              </S.SaveBox>
            </div>
            <DesignViewer>
              {question1 && <Show3DView props = {this.props}/>}
              {question2 && <Show2DView props = {this.props}/>}
              {question2 && <ShowFrontElevationView props = {this.props}/>}
              {question2 && <ShowBackElevationView props = {this.props}/>}
              {question2 && <ShowLeftElevationView props = {this.props}/>}
              {question2 && <ShowRightElevationView props = {this.props}/>}
              {question3 && <ShowCabinetInfo productDATA= {window.productData || null} totalPrice = {window.totalPrice}/>}
              <ShowWarranty/>
            </DesignViewer>
          </div>
        </StyledDialog>
      </div>
    );
  }
}

export default DownloadSummaryPopup;
