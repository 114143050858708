import React,{useEffect} from 'react';
import moment from "moment";
import Viewer3DDownLoad from "./viewer3DDownLoad";
import * as constants from "../../../../../constants";

const Show3DView = ({ props }) => {

  useEffect(()=>{
    setTimeout(()=>saveSVGScreenshotToFile(),5000);
  });

  const saveSVGScreenshotToFile = async () => {
    let canvas2D3DPlain1 = document.getElementById("pdf3DPlain");

    if (canvas2D3DPlain1 !== null) {
      let canvas2D3DPlain =canvas2D3DPlain1.getElementsByTagName('canvas')[0];
      if (canvas2D3DPlain !== undefined) {
        window.pdf3DPlain = canvas2D3DPlain.toDataURL();
      }
    } else {
      window.pdf3DPlain = "";
    }
  };

  return (
    <div style={{ width: '100%', minHeight:'100%', backgroundColor: '#fff', marginBottom: '10px' }}>
      <div id="pdf3DPlain" style={{ transform: `scale(0.5, 0.5)`, width:6, height:2}}>
        <Viewer3DDownLoad state={props.state} width={904} height={700}/>
      </div>
      <div style={{ padding: '360px 30px 30px 30px' }}>
        <div style={{ alignItems: 'center', padding: '5px 0' }}>
          <div style={styles.headerInfo}>
            <span style={{}}>Created for:<b style={{color: 'rgba(119,119,119,0.9)', marginLeft:5}}>{sessionStorage.getItem('firstName') + ' ' + sessionStorage.getItem('lastName')}</b></span>
            <span style={{}}>Date: <b style={{color: 'rgba(119,119,119,0.9)', marginLeft:5}}>{moment(new Date()).format('DD-MM-YYYY')}</b></span>
            <span style={{}}>ID: <b style={{color: 'rgba(119,119,119,0.9)', marginLeft:5}}>#{sessionStorage.getItem('projectId')}</b></span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: "11px" }}>
            <div style={{ flex: 4 }}>
              <h2 style={{margin:'7px 0'}}>{sessionStorage.getItem('projectTitle')}</h2>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <span style={{fontSize:'10px',color:'rgba(119,119,119,0.9)'}}><b>Summary ID: #{sessionStorage.getItem('projectId')}{moment(new Date()).format('DD-MM-YYYY')}</b></span>
                <div style={{width:1, height:14, backgroundColor:'#8791AB'}}></div>
                <span style={{fontSize:'10px',color:'rgba(119,119,119,0.9)'}}><b>Available: {moment(new Date()).format('DD-MM-YYYY')}</b></span>
              </div>
            </div>
            <div style={{ flex: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <div style={{ height: 70, width: 70 }}>
                <img src="/assets/rtastore_logo.png" width="100%" height="100%" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: '30px 0',textAlign: 'justify',marginTop:'131px', marginBottom: '40px',fontSize:'10px', color:'rgba(120,120,120,0.8)' }}>
          <b>Disclaimer:</b> DIY Design Space not take responsibility for the
          accuracy of measurements or furniture arrangements. The prices
          displayed here apply to items in stock at the time of the offer's
          issuance. All delivery, assembly, and installation services required
          are billed are billed separately and are not included in the price.
          While we strive to ensure the accuracy of the information in this
          program, we apologize for any errors that may occur.
        </div>
        <div style={styles.footer }>
          <span>© DIY.DS v1.0/2024</span>
          <span>DIY Design Space® 2024 All rights reserved</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  headerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '30px',
    fontFamily: 'sans-serif',
    color: 'rgba(119,119,119,0.9)',
    borderBottom: '1px solid rgba(119,119,119,0.47)',
    fontSize:'10px'
  },
  footer:{
    padding: '15px 0',
    borderTop: '1px solid rgba(119,119,119,0.47)',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'sans-serif',
    color: 'rgba(119,119,119,0.9)',
    fontSize: '10px',
  }
}

export default Show3DView;
